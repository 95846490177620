export const publicRoutes = [
    '/',
    '/about-us/',
    '/login/',
    '/register/',
    '/verify-account',
    '/forgot-password',
    '/reset-password',
    '/success-create-account',
    '/complete-account',
    '/all-categories/',
    '/all-projects/',
    '/article-details/',
    '/articles/',
    '/contact-us/',
    '/featured-properties/',
    '/most-favorite-properties/',
    '/most-viewed-properties/',
    '/privacy-policy/',
    '/properties/all-properties/',
    '/properties/categories/',
    '/properties/city/',
    '/properties-details/',
    '/project-details/',
    '/properties-nearby-city/',
    '/properties-on-map/',
    '/search/',
    '/subscription-plan/',
    '/terms-and-condition/',
]

export const isSubscribeRoutes = [
    // '/user/dashboard/',
    // '/user/advertisement/',
    // '/user/properties/',
    // '/user/subscription/',
    // '/user/transaction-history/',
    // '/user/intrested/',
];

export const protectedRoutes = [
    '/user/chat/',
    '/user-register/',
    '/user/profile/',
    '/user/favorites-properties/',
    '/user/personalize-feed/',
    '/user/request-list/',
    '/user/current-listing/',
    '/user/request-addition-list/',


    '/user/dashboard/',
    '/user/advertisement/',
    '/user/properties/',
    '/user/properties/post-listing/',
    '/user/subscription/',
    '/user/history/',
    '/user/intrested/',

    //pay deposit flow routes
    'pay/payment-types/'
]
